import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import theme from '../utils/theme';

function Landing({ clickHandler }) {
  return (
    <Container>
      <Row>
        <Col className="d-flex flex-column px-5">
          <h1 style={theme.heading}>Hey! I'm McRae.</h1>
          <p style={theme.cardHeading}>I like making websites.</p>
          <Button
            style={theme.button}
            className="mx-auto"
            variant="light"
            onClick={clickHandler}
          >
            Explore My Work
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Landing;
