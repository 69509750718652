import React from 'react';
import './App.css';
import Landing from './pages/Landing';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import background from './assets/background.png';
import Portfolio from './pages/Portfolio';
import Aboutme from './pages/Aboutme';

class App extends React.Component {
  scroll = (to) => this.DOMnode.scrollTo(to);
  getRef = (node) => {
    this.DOMnode = node;
  };

  render() {
    return (
      <Parallax ref={this.getRef} pages={4}>
        <ParallaxLayer
          offset={0}
          speed={0}
          factor={4}
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
          }}
        />
        <ParallaxLayer offset={0.4} speed={0.3}>
          <Landing
            clickHandler={() => {
              this.scroll(1);
            }}
          />
        </ParallaxLayer>
        <ParallaxLayer offset={1.1} speed={0.3}>
          <Portfolio
            clickHandler={() => {
              this.scroll(2.3);
            }}
          />
        </ParallaxLayer>
        <ParallaxLayer offset={2.4} speed={0.3}>
          <Aboutme
            clickHandler={() => {
              console.log('click');
              this.scroll(0);
            }}
          />
        </ParallaxLayer>
      </Parallax>
    );
  }
}

export default App;
