export default {
  heading: {
    fontSize: '45px',
    color: 'white',
    textAlign: 'center',
  },
  subHeading: {
    fontSize: '30px',
    color: 'white',
    textAlign: 'center',
  },
  cardHeading: {
    fontSize: '20px',
    color: 'white',
    textAlign: 'center',
  },
  text: {
    fontSize: '16px',
    color: 'white',
    textAlign: 'center',
    fontWeight: 'lighter',
  },
  paragraph: {
    fontSize: '16px',
    color: 'white',
    textAlign: 'left',
    fontWeight: 'lighter',
  },
  button: {
    width: '150px',
  },
  portfolioBackground: {
    backgroundColor: '#12141D',
    borderRadius: '20px',
  },
  card: {
    backgroundColor: '#1B2337',
    height: '28rem',
    width: '30rem',
  },
  headshot: {
    width: '15rem',
    height: '15rem',
  },
};
