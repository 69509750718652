import React from 'react';
import { Button, Container, Row, Col, Image } from 'react-bootstrap';
import theme from '../utils/theme';
import headshot from '../assets/headshot.png';
import { Github, Instagram, ContactInfo, Linkedin } from 'grommet-icons';
import resume from '../assets/charles-mcrae-peavy-resume.pdf';

function Aboutme({ clickHandler }) {
  return (
    <Container>
      <Row>
        <Col className="d-flex justify-content-center px-5">
          <Image
            style={theme.headshot}
            src={headshot}
            alt="Charles McRae Peavy headshot"
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center px-5">
          <p style={theme.paragraph}>
            A quick, self-starting, active learner, I enjoy all stages of the
            web-development process. I graduated from the University of Houston
            with a degree in Economics and a minor in Journalism. I earned a
            Full Stack Coding Certificate from the University of Texas in 2020,
            and have pursued web development since. With a strong willingness
            learn any subject in which I lack knowledge, my background in
            journalism provides me with the research skills necessary to
            continue growing in this ever changing field. Email me at
            mcraepv@gmail.com
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center px-5">
          <p style={theme.paragraph}>
            Skills: JavaScript, ES6, HTML, CSS, TypeScript, SQL Bootstrap,
            Foundation, Angular, React, Node.js, Express, Git, Firebase, RESTful
            APIs, MySQL, MongoDB, JSON Web Tokens, jQuery, Test Driven
            Development, MVC, Technical Writing, Data Analysis, Research,
            Written Communication
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center px-5">
          <p style={theme.text}>Find me on the internet:</p>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center px-5">
          <Button variant="link" size="lg" href={resume} target="_blank">
            <ContactInfo color="white" size="large" />
          </Button>
          <Button
            variant="link"
            size="lg"
            href="https://github.com/mcraepv"
            target="_blank"
          >
            <Github color="white" size="large" />
          </Button>
          <Button
            variant="link"
            size="lg"
            href="https://www.linkedin.com/in/mcraepv/"
            target="_blank"
          >
            <Linkedin color="white" size="large" />
          </Button>
          <Button
            variant="link"
            size="lg"
            href="https://www.instagram.com/mcraepv/"
            target="_blank"
          >
            <Instagram color="white" size="large" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Aboutme;
