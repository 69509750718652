import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import theme from '../utils/theme';
import MyPagination from './Pagination';
import PortfolioCard from '../components/PortfolioCard';
import portfolioProps from '../utils/portfolioProps';

function Portfolio({ clickHandler }) {
  const {
    gameflowProps,
    saludProps,
    librarioProps,
    buildATeamProps,
  } = portfolioProps;

  return (
    <Container style={theme.portfolioBackground}>
      <Row className="justify-content-center">
        <Col className="d-flex flex-column">
          <Container className="mb-4 d-none d-xl-block">
            <Row className="my-3">
              <Col>
                <h2 style={theme.subHeading}>My Work</h2>
              </Col>
            </Row>
            <Row className="mt-1 justify-content-center">
              <Col className="d-flex justify-content-center ml-xl-3 mt-3">
                <PortfolioCard {...gameflowProps} />
              </Col>
              <Col className="d-flex justify-content-center mr-xl-3 mt-3">
                <PortfolioCard {...saludProps} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-center ml-xl-3 mt-3">
                <PortfolioCard {...librarioProps} />
              </Col>
              <Col className="d-flex justify-content-center mr-xl-3 mt-3">
                <PortfolioCard {...buildATeamProps} />
              </Col>
            </Row>
          </Container>
          <MyPagination />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center mb-4">
          <Button
            style={theme.button}
            className="mx-auto"
            variant="light"
            onClick={clickHandler}
          >
            About Me
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Portfolio;
