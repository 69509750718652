import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Pagination } from 'react-bootstrap';
import theme from '../utils/theme';
import portfolioProps from '../utils/portfolioProps';
import PortfolioCard from '../components/PortfolioCard';

const MyPagination = () => {
  const {
    gameflowProps,
    saludProps,
    librarioProps,
    buildATeamProps,
  } = portfolioProps;
  const handleNext = (i) => {
    let cardProps;
    switch (i) {
      case 1:
        cardProps = gameflowProps;
        break;
      case 2:
        cardProps = saludProps;
        break;
      case 3:
        cardProps = librarioProps;
        break;
      case 4:
        cardProps = buildATeamProps;
    }
    return <PortfolioCard {...cardProps} />;
  };

  const [curr, setCurr] = useState();
  const [currNum, setCurrNum] = useState(1);

  useEffect(() => {
    handleNext(1);
  }, []);

  useEffect(() => {
    setCurr(handleNext(currNum));
  }, [currNum]);

  return (
    <Container className="mb-4 d-block d-xl-none">
      <Row className="my-3">
        <Col>
          <h2 style={theme.subHeading}>My Work</h2>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">{curr}</Col>
      </Row>
      <Row>
        <Col>
          <Pagination className="d-flex justify-content-center mt-5">
            <Pagination.Prev
              onClick={() => [
                currNum !== 1
                  ? setCurrNum((prevState) => (prevState -= 1))
                  : setCurrNum(4),
              ]}
            />
            <Pagination.Item
              onClick={() => {
                setCurrNum(1);
              }}
            >
              {1}
            </Pagination.Item>
            <Pagination.Item
              onClick={() => {
                setCurrNum(2);
              }}
            >
              {2}
            </Pagination.Item>
            <Pagination.Item
              onClick={() => {
                setCurrNum(3);
              }}
            >
              {3}
            </Pagination.Item>
            <Pagination.Item
              onClick={() => {
                setCurrNum(4);
              }}
            >
              {4}
            </Pagination.Item>
            <Pagination.Next
              onClick={() => [
                currNum !== 4
                  ? setCurrNum((prevState) => (prevState += 1))
                  : setCurrNum(1),
              ]}
            />
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

export default MyPagination;
