import React from 'react';
import { Button, Card } from 'react-bootstrap';
import theme from '../utils/theme';
import { Github, Share } from 'grommet-icons';

const PortfolioCard = ({ pic, header, text, gitLink, link, alt }) => {
  return (
    <Card style={theme.card}>
      <Card.Img variant="top" src={pic} alt={alt} />
      <Card.Body className="text-center">
        <Card.Title style={theme.subHeading}>{header}</Card.Title>
        <Card.Text style={theme.text}>{text}</Card.Text>
        <Button
          variant="link"
          size="lg"
          href={gitLink}
          target="_blank"
          className=" mr-1"
        >
          <Github color="white" size="medium" />
        </Button>
        {link ? (
          <Button
            variant="link"
            size="lg"
            href={link}
            target="_blank"
            className="ml-1"
          >
            <Share color="white" size="medium" />
          </Button>
        ) : null}
      </Card.Body>
    </Card>
  );
};

export default PortfolioCard;
