import gameflowPic from '../assets/gameflowpic.png';
import saludPic from '../assets/saludpic.png';
import buildATeamPic from '../assets/build-a-teampic.png';
import librarioPic from '../assets/librariopic.png';

const portfolioProps = {
  gameflowProps: {
    pic: gameflowPic,
    alt: 'Gameflow homepage',
    header: 'GameFlow',
    text: `A quiz app to solve the ever-present choice paralysis problem that
          many gamers experience. Created with the MERN stack.`,
    gitLink: 'https://github.com/mcraepv/GameFlow',
    link: 'http://secret-wildwood-10554.herokuapp.com/',
  },
  saludProps: {
    pic: saludPic,
    alt: 'Salud homepage',
    header: 'Salud!',
    text: `A cocktail recipe site that cuts out the clutter so users can
                find the instructions they need quickly and without fuss. 
                Made with the MEAN stack.`,
    gitLink: 'https://github.com/mcraepv/Salud',
    link: 'http://salud-cocktails.herokuapp.com/home',
  },
  librarioProps: {
    pic: librarioPic,
    alt: 'Librario homepage',
    header: 'Librario',
    text: `A book search and journaling app that allows users to find new
                books and keep notes as they read them. Created with jQuery and
                Firebase.`,
    gitLink: 'https://github.com/mcraepv/librar.io',
    link: 'https://mcraepv.github.io/librar.io/',
  },
  buildATeamProps: {
    pic: buildATeamPic,
    alt: 'Build-A-Team output',
    header: 'Build-A-Team',
    text: `A Node CLI that auto-generates a team directory app based on
                given user information. Uses Inquirer for user interface and Bootstrap for output styling.`,
    gitLink: 'https://github.com/mcraepv/build-a-team',
    cardKey: 4,
  },
};

export default portfolioProps;
